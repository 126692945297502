#landing {
  width: 100%;
  min-height: 500px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro {
  position: absolute;
  top: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  box-sizing: border-box;
  width: 80vw;

  div {
    width: fit-content;
  }

  h1 {
    color: $light-accent;
    font-size: 1.4em;
    font-weight: bold;
    font-family: 'Fira Code', monospace;
    overflow: hidden;
    border-right: 3px solid $highlight;
    white-space: nowrap;
    width: fit-content;
    animation:
      typing 2s steps(22, end),
      cursor 0.8s infinite;
  }

  p {
    color: $dark-accent;
    font-size: 1.2em;
    margin: 0;
  }

  span {
    padding: 0;
    color: $highlight;
    font-weight: bold;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes cursor {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: $highlight;
  }
}

.fa-square-full {
  font-size: 0.28em;
}

.fa-angle-double-down {
  visibility: hidden;
  position: absolute;
  bottom: 20px;
  font-size: 3em;
  color: $light-accent;
}
