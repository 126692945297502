.navbar {
  background-color: white;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  width: 100vw;
  height: 50px;
  padding: 5px 0 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;

  a {
    font-size: 1.2em;
    font-weight: bold;
    color: $light-accent;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 5px 0;
  }

  a:hover {
    color: $highlight;
  }
}

ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

li {
  list-style-type: none;
  width: 4.5em;
}

.home-btn {
  width: 0;

  img {
    box-sizing: border-box;
    padding: 0.8em;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
