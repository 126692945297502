
svg {
  width: 2000px;
  position: absolute;
  z-index: -1;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

.overlays {
  position: absolute;
  width: 100%;
  height: 150%;
  left: 0;
  overflow: hidden;
  z-index: -1;
}

.bright-overlay {
  transform-origin: top;
  top: 0;
  fill: #f88020;
  transform: scaleY(0.5);
}

.white-overlay {
  transform-origin: top;
  top: 0;
  fill: $base;
  transform: scaleY(1.45);
}

.contact-overlay {
  fill: #3d0e1e;
  bottom: 0;
  left: 0;
  transform-origin: bottom;
  transform: scaleY(0.35);
}
