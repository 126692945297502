@media only screen and (min-height: 500px) {
  .fa-angle-double-down {
    visibility: visible;
  }
}

@media only screen and (min-width: 500px) {
  .content section,
  footer {
    padding-right: 80px;
    box-sizing: border-box;
  }

  .navbar {
    top: 0;
    bottom: 0;
    left: auto;
    height: 100vh;
    width: 80px;
    padding: 0 5px 0 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

    ul {
      flex-direction: column;
      justify-content: center;
    }

    li {
      width: 60%;
      padding: 10px 0;
    }
  }

  .home-btn img {
    height: auto;
    width: 100%;
  }

  .intro,
  .card {
    width: 70vw;
  }

  .intro h1 {
    font-size: 1.7em;
  }

  #projects {
    padding-top: 0;
  }

  #contact a {
    padding: 0 4vw;
  }
}

@media only screen and (min-width: 800px) {
  body {
    font-size: 0.95em;
  }

  .navbar a {
    font-size: 1.1em;
  }
}

@media only screen and (min-width: 900px) {
  .card {
    width: 38vw;
  }
}

@media only screen and (min-width: 1100px) {
  .card {
    width: 40vw;
  }
}

@media only screen and (min-width: 1206px) {
  .intro {
    width: 800px;
  }
}

@media only screen and (min-width: 1277px) {
  body {
    font-size: 1em;
  }

  #projects {
    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    padding-top: 40px;
    padding-bottom: 40px;

    .image-container {
      height: 100%;
    }

    img,
    .hover-screen::before {
      width: auto;
      height: 420px;
      border-bottom: none;
      border-right: 5px groove $light-accent;
      border-radius: 3px 30px 30px 3px;
    }

    .hover-screen::before {
      width: 100%;
    }
  }

  .card {
    width: 78vw;
    height: 420px;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1277px) {
  .card {
    width: 1092px;
  }
}

@media only screen and (min-width: 1710px) {
  .bright-overlay {
    transform: scaleY(0.6);
  }

  .white-overlay {
    transform: scaleY(1.6);
  }
}

@media only screen and (min-width: 1910px) {
  .bright-overlay {
    transform: scaleY(0.7);
  }

  .white-overlay {
    transform: scaleY(1.75);
  }
}

@media only screen and (min-width: 2000px) {
  svg {
    width: 2500px;
  }

  .bright-overlay {
    transform: scaleY(0.5);
  }

  .white-overlay {
    transform: scaleY(1.4);
  }
}

@media only screen and (min-width: 2500px) {
  svg {
    width: 3000px;
  }

  .bright-overlay {
    transform: scaleY(0.4);
  }

  .white-overlay {
    transform: scaleY(1.2);
  }
}
